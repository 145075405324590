import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import Footer from '../Home/Footer';
import { Table, Divider } from 'antd';
import Logo from './images/logo.jpg';
import { useTranslation } from 'react-i18next';

export default function Privacidad() {
  const [t, i18n] = useTranslation('global');
  const list = {
    paddingLeft: 20,
    listStyle: 'none'
  };

  const columns1 = [
    {
      title: '',
      dataIndex: 'content',
      width: '12%',
    },
    {
      title: '',
      dataIndex: 'description',
      width: '12%',
    },
  ];
  const data1 = [
    {
      key: '1',
      content: 'Nosotros, los responsables del tratamiento de sus datos',
      description:
        'Identidad: ECOSCOOTING DELIVERY SL NIF: B65874893 Domicilio social: Ronda de Marcos Ana 6 Nave DC&, 28830, Madrid Correo electrónico: privacy@ecoscooting.com',
    },
    {
      key: '2',
      content: 'Nuestro Delegado de Protección de Datos (DPD)',
      description:
        'Si tiene cualquier tipo de consulta, duda o sugerencia con relación a cómo usamos sus datos personales puede dirigirse al Delegado de Protección de Datos a través de la dirección de correo electrónico: xavi@aurisadvocats.com',
    },
    {
      key: '3',
      content: 'Esta política de privacidad es extensible a la empresa: ',
      description:
        'ECOXPRESS SOLUCIONES Y SERVICIOS SLU CIF: B-88035266 Domicilio Social: calle Alcalaten, n-° 4 Polígono de La Cova, 46940, Manises (Valencia)',
    },
  ];
  const columns2 = [
    {
      title: 'Finalidad del tratamiento ¿Para qué recogemos su información?',
      dataIndex: 'content',
      width: '12%',
    },
    {
      title: 'Legitimidad legal para tratar su información personal',
      dataIndex: 'description',
      width: '12%',
    },
  ];
  const data2 = [
    {
      key: '1',
      content:
        'Proporcionarle nuestros servicios, aceptar pagos y cobros debidos',
      description:
        '(1) Ejecución contractual (2) En interés legítimo propio (por ejemplo, para gestionar eventuales impagados o para responder a posibles reclamaciones sobre incidencias en los envíos) ',
    },
    {
      key: '2',
      content: 'Registrarse como usuario web o nuevo cliente',
      description:
        '(1) Consentimiento expreso del interesado (2) Ejecución contractual',
    },
    {
      key: '3',
      content:
        'Gestionar nuestra relación con nuestros clientes, que incluye: (1) Notificarle de cambios en nuestras condiciones de contratación o políticas. (1) Notificarle de cambios en nuestras condiciones de contratación o políticas (2) Solicitar que respondan una encuesta o valoren nuestros productos/servicios',
      description:
        `
        (1) Ejecución contractual
        (2) Cumplimiento de una obligación legal
        (3) En interés legítimo propio (para actualizar nuestros registros y conocer la opinión de  nuestros clientes a cerca de nuestros servicios)
        `,
    },
    {
      key: '4',
      content:
        `Remitir las comunicaciones comerciales, boletines, por cualquier canal de comunicación`,
      description:
        `(1) Consentimiento expreso del interesado
        (2) En interés legítimo propio (siempre no haya expresado su deseo a dejar de recibir comunicación, ‘opt-out’)`,
    },
    {
      key: '5',
      content:
        'Remitir las comunicaciones comerciales, boletines, por cualquier canal de comunicación',
      description:
        '(1) Consentimiento expreso del interesado (2) En interés legítimo propio (siempre no haya expresado su deseo a dejar de recibir comunicación, ‘opt-out’)',
    },
    {
      key: '6',
      content:
        'Responder a las consultas y/o proporcionar información requerida por el interesado, incluyendo el envío de presupuestos',
      description:
        '(1) Interés legítimo propio (2) Ejecución contractual (3) Consentimiento del interesado (4) Cumplimiento de una obligación legal',
    },
    {
      key: '6',
      content:
        'Gestionar las interactuaciones de los usuarios en nuestras redes sociales',
      description:
        '(1) Cumplimiento de una obligación legal (por ejemplo para eliminar comentarios ofensivos, racistas, soeces o injuriosos; mantener un ambiente de respeto e integración, preservar la intimidad de menores, etc.) (2) Interés legítimo propio (cuando eliminamos publicidad de terceros de nuestras redes, por ejemplo)',
    },
    {
      key: '7',
      content:
        'Utilizar datos analíticos para mejorar la experiencia de navegación web/ uso de app, implementar estrategias de marketing y optimizar procesos de contratación mediante el uso de cookies. ',
      description:
        '(1) Interés legítimo propio (2) Consentimiento del interesado (al aceptar el uso de cookies analíticas, por ejemplo)',
    },
    {
      key: '8',
      content:
        'Administrar y proteger nuestro negocio y nuestra página web. Esto incluye la detección de problemas de navegación, análisis de datos, testeos web/app, etc.',
      description:
        '(1) Cumplimiento de una obligación legal (2) Interés legítimo propio (llevanza de nuestra empresa, proveer de seguridad nuestras redes, prevenir el fraude, etc.)',
    },
    {
      key: '9',
      content:
        'Sugerirle y recomendarle los productos y servicios que pudieren ser de interés',
      description:
        '(1) En interés legítimo (para hacer crecer nuestro negocio)',
    },
    {
      key: '10',
      content:
        'Proveer de información personal a las autoridades o por requerimiento judicial ',
      description: 'Cumplimiento de una obligación legal',
    },
    {
      key: '11',
      content:
        'Dotar de mayor seguridad nuestras instalaciones físicas (instalación de cámaras de CCTV/ video vigilancia, control de accesos)',
      description:
        'En interés legítimo y en interés de terceros. Por ejemplo para detectar la comisión de un acto dañino para nuestros empleados o clientes. ',
    },
    {
      key: '12',
      content: 'Actualización y mejora de nuestros registros de clientes',
      description:
        '(1) En cumplimiento de una obligación legal (2) En ejecución de un contrato (3) En interés legítimo (para verificar que podemos seguir en contacto con nuestros clientes para cuestiones relacionadas con sus suscripciones, pedidos o productos adquiridos).',
    },
    {
      key: '13',
      content:
        'Garantizar la seguridad en el puesto de trabajo, administración de personal y empleabilidad de candidatos',
      description:
        '(1) En cumplimiento de una obligación legal (2) En interés legítimo propio y de terceros. Para mejorar la experiencia de nuestros empleados en el ejercicio de sus funciones.',
    },
    {
      key: '14',
      content:
        'Ayuda en línea para la compra de productos o servicios -conversaciones instantáneas contextualizadas (chatbot)',
      description:
        '(1)	Consentimiento expreso del interesado (2)	Cumplimiento del contrato (3) Interés legítimo (mejora de nuestro servicio al cliente)',
    },
    {
      key: '16',
      content:
        'Responder a posibles reclamaciones sobre incidencias en el envío.',
      description: '(1) Interés legítimo',
    },
  ];
  const columns3 = [
    {
      title: 'Materia',
      dataIndex: 'content',
      width: '12%',
    },
    {
      title: 'Prescripción',
      dataIndex: 'description',
      width: '12%',
    },
    {
      title: 'Normativa',
      dataIndex: 'normativa',
      width: '12%',
    },
  ];
  const data3 = [
    {
      key: '1',
      content: 'Laboral, a efectos de infracciones',
      description: '3 años',
      normativa: 'Art. 4.1 RD 5/2000',
    },
    {
      key: '2',
      content: 'Seguridad Social, a efectos de infracciones',
      description: '4 años',
      normativa: 'Art. 4.2 RD 5/2000',
    },
    {
      key: '3',
      content: 'Prevención de Riesgos Laborales, a efectos de infracciones',
      description: '5 años',
      normativa: 'Art. 4.3 RD 5/2000',
    },
    {
      key: '4',
      content: 'Fiscal, a efectos de deudas tributarias',
      description: '4 años',
      normativa: 'Art. 66 Ley 58/2003',
    },
    {
      key: '5',
      content:
        'Fiscal, a efectos de comprobaciones de cuotas compensadas o deducciones aplicadas',
      description: '10 años',
      normativa: 'Art. 66 bis Ley 58/2003',
    },
    {
      key: '6',
      content: 'Contable y mercantil',
      description: '6 años',
      normativa: 'Art. 30 del CC',
    },
    {
      key: '7',
      content: 'Delitos contra Hacienda Pública y Seguridad Social',
      description: '10 años',
      normativa: 'Art. 131 LO 10/1995',
    },
    {
      key: '8',
      content: 'Responsabilidad contractual',
      description: '5 años',
      normativa: 'Art. 1968.2 del CC',
    },
    {
      key: '9',
      content: 'Responsabilidad extracontractual',
      description: '1 año',
      normativa: 'Art. 1964.2 del CC',
    },
  ];
  const columns4 = [
    {
      title: '',
      dataIndex: 'content',
      width: '12%',
    },
    {
      title: '',
      dataIndex: 'description',
      width: '12%',
    },
  ];
  const data4 = [
    {
      key: '1',
      content: 'Derecho de acceso',
      description: 'El derecho a recibir copia de su información personal.',
    },
    {
      key: '2',
      content: 'Derecho de rectificación',
      description:
        'El derecho a solicitar la corrección de errores en la información personal.',
    },
    {
      key: '3',
      content: 'Derecho de cancelación/supresión (derecho al olvido)',
      description:
        'El derecho a solicitar que eliminemos su información personal – en algunas situaciones.',
    },
    {
      key: '4',
      content: 'Derecho de limitación',
      description:
        'El derecho a solicitar la restricción del tratamiento de sus datos.',
    },
    {
      key: '5',
      content: 'Derecho de oposición',
      description:
        'El derecho a oponerse a: -al tratamiento de tus datos para marketing directo (incluido la creación de perfiles). -en ciertas circunstancias a que sigamos tratando sus datos. Por ejemplo, el tratamiento efectuado en base a nuestro interés legítimo.',
    },
    {
      key: '6',
      content: 'Derecho de portabilidad',
      description:
        'El derecho a recibir su información personal en una forma estructurada, en un formato legible y/o transmitir estos datos a un tercero – en situaciones concretas.',
    },
    {
      key: '7',
      content: 'Decisiones individuales automatizadas',
      description:
        'Derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles, que produzca efectos jurídicos o afecte significativamente.',
    },
  ];
  return (
    <div>
      <div className="container">
        <Row>
          <Col lg={3} sm={24} xs={24} span={8}></Col>
          <Col lg={18} sm={24} xs={24}>
            <Link to="/">
              <img
                alt="Ecoscooting Delivery"
                src={Logo}
                style={{ width: '30%', marginBottom: '50px' }}
              />
            </Link>
            <div className="politicas">
              <h1>{t('Pol-Priv.pol-title')}</h1>
              <p>Última actualización 15 de febrero de 2024</p>
              <p>
                ECOSCOOTING DELIVERY SL (en adelante, ECOSCOOTING) como
                Responsable del Tratamiento y de este sitio web, de conformidad
                con lo que dispone el Reglamento (UE) 2016/679, General de
                Protección de Datos y de la Ley Orgánica 3/2018, de 5 de
                diciembre, de Protección de Datos Personales y garantía de los
                derechos digitales, pone a su disposición la presente política
                de privacidad con la finalidad de informarle, de forma
                detallada, sobre cómo tratamos sus datos personales y protegemos
                su privacidad y la información que nos proporciona. En esta
                política de privacidad le explicamos cuales son sus derechos en
                cuanto a su información personal y el modo de ejercerlos.
                Adicionalmente, en caso de que necesite dirigirse a la autoridad
                competente en materia de protección de datos, le proporcionamos
                el contacto.
              </p>
              <br />
              <Table
                columns={columns1}
                dataSource={data1}
                size="middle"
                bordered
                showHeader={false}
                pagination={false}
              />
              <br />
              <h2>¿Qué tipo de información sobre Ud. podríamos recoger?</h2>
              <br />
              <p>
                Los datos personales que se recabamos de los usuarios y
                clientes, se pueden agrupar según las siguientes categorías:
              </p>
              <p>
                - Datos básicos y de contacto: como pueden ser el nombre, los
                apellidos, el nombre de usuario o identificador similar, su
                estado civil, titulación, fecha de nacimiento o género.
                Incluyendo también su dirección de facturación y entrega, email
                y número de teléfono. También los datos del carnet de conducir.
                Datos financieros y económicos: en este grupo quedarían
                incluidos los detalles de pago, devolución y reintegro, así como
                las transacciones comerciales efectuadas con nosotros.
                Incluyendo datos de verificación de du identidad para la
                aceptación de pagos y las comprobaciones financieras pertinentes
                para poder llevar a cabo las transacciones comerciales.
              </p>
              <br />
              <p>
                - Datos profesionales y de empleo: en esta categoría se
                englobarían sus intereses profesionales y su identidad
                profesional publicada online. Por ejemplo, su perfil de
                LinkedIn.
              </p>
              <p>
                - Datos técnicos: incluyendo la dirección IP, datos de registro,
                navegador y versión utilizados, la zona y uso horarios, tipo de
                plugins instalados en su navegador, sistema operativo y demás
                tecnología utilizada durante el acceso a nuestra plataforma.
              </p>
              <p>
                - Datos de su cuenta de usuario:como el nombre de perfil y
                contraseña.
              </p>
              <p>
                - Datos de navegación: incluye información relativa a su modo de
                navegación cuando visita nuestra plataforma.
              </p>
              <p>
                - Preferencias de marketing y comunicación: recabamos sus
                preferencias para recibir comunicaciones comerciales y noticias
                nuestras, los consentimientos otorgados para ello y el canal de
                su elección.
              </p>
              <p>
                - Imágenes captadas por cámaras de videovigilancia: aquí se
                agrupan las imágenes que podrían captarse mediante cámaras de
                videovigilancia en los establecimientos comerciales si los
                hubiera.
              </p>
              <p>
                - Datos de los destinatarios del reparto: Existen dos
                casuísticas:
              </p>
              <p>
                a.- Cuando Ecoscooting Delivery SL actúa como encargado de
                tratamiento: Para la ejecución de la prestación derivada del
                cumplimiento del objeto del contrato de encargo de tratamiento,
                el Responsable de Tratamiento (la empresa suministradora de los
                bienes), pone a disposición del Encargado de Tratamiento
                (Ecoscooting Delivery SL) la siguiente información: Nombre,
                apellidos, dirección de entrega y teléfono. Asimismo, des de
                Ecoscooting se solicitará al destinatario su DNI, tanto para
                programar la entrega a terceras personas o buzón como en el
                momento de entrega del paquete para poder responder ante
                posibles reclamaciones sobre incidencias en el envío.
                <br /> b.- Cuando Ecoscooting Delivery SL actúa como Responsable
                de tratamiento: En el marco del cumplimiento del contrato de
                prestación de servicios con la empresa suministradora de los
                bienes y en estricto apego a la normativa de protección de
                datos, la empresa suministradora de los bienes, en calidad de
                Responsable del Tratamiento, facilita al Responsable
                independiente del tratamiento (Ecoscooting Delivery SL) la
                siguiente información: Nombre, apellidos, dirección de entrega y
                teléfono. Todos los datos personales mencionados serán tratados
                y resguardados adecuadamente, conforme a la normativa vigente en
                materia de protección de datos. Asimismo, Ecoscooting solicitará
                al destinatario su DNI, tanto para programar la entrega a
                terceras personas o buzón como en el momento de entrega del
                paquete, con el objetivo de responder ante posibles
                reclamaciones relacionadas con incidencias en el envío. A través
                de este enlace puede acceder a las empresas suministradoras de
                servicios que facilitan datos personales a Ecoscooting. Nunca
                recabaremos datos personales especialmente protegidos o
                considerados sensibles.
              </p>
              {/* <p>
                - También podemos recopilar otra información relevante
                razonablemente necesaria para la prestación de los servicio y la
                mejora de la calidad del servicio. Dicha información puede
                incluir la información relevante proporcionada por usted al
                ponerse en contacto con el equipo de atención al cliente de
                ECOSCOOTING.
              </p>
              <p>
                - Información sobre LOS registros logísticos, que se le enviará
                por correo electrónico, SMS, Whatsapp, etc.
              </p>
              <p>
                Nunca recabaremos datos personales especialmente protegidos o
                considerados sensibles.
              </p> */}
              <br />

              <h2>¿Cómo recogemos sus datos personales?</h2> <br />
              <p>
                Como norma general, la mayor parte de su información personal nos la proporciona directamente Ud. ya sea presencialmente, por teléfono, correo, formularios web o respondiendo a encuestas. Sin embargo, también podemos obtener información de:
              </p>
              <p style={list}>
                <ul>
                  <li>De terceros vinculados con nosotros, como por ejemplo:</li>
                  <li>De su empleador</li>
                  <li>De una tercero que haya recabado previamente su consentimiento expreso para ello, como por ejemplo empresas que nos contratan para prestar servicios de reparto. </li>
                  <li>De las cookies que habilitamos en nuestra web – Para información ampliada sobre el uso de nuestras cookies, puede visitar nuestra política de cookies.  </li>
                  <li>De nuestros sistemas de acceso a las instalaciones, cuando los hubiere. Como por ejemplo serían los registros de entrada y recepción, los sistemas de fichado en el caso de empleados, cámaras de videovigilancia, sistemas de comunicación y mensajería instantánea, email o redes sociales. </li>
                </ul>
              </p>
              <br />
              <h2>
                ¿Qué puede ocurrir si no nos facilita su información personal?
              </h2>
              <p>
              Cuando estemos obligados por ley a recoger sus datos personales o, cuando éstos resulten imprescindibles para celebrar un contrato con usted, en el caso de que no nos quiera facilitar sus datos personales,  es posible que nos sea imposible servirle.  En el caso eventual de que nos veamos en la necesidad de cancelar nuestros servicios por ello, le notificaremos primero cuando fuere necesario. 
              </p>
              <br />
              <h2>¿Con qué finalidad tratamos su información personal?</h2>
              <p>
                <h2>¿CON QUE FINALIDAD TRATAMOS SU INFORMACIÓN PERSONAL?</h2>{' '}
                <br />
                <p>
                  Adjuntamos una tabla detallada con la finalidad para la que
                  recogemos sus datos y la base legal que nos legitima para
                  ello.{' '}
                </p>
                <br />
                <Table
                  columns={columns2}
                  dataSource={data2}
                  size="middle"
                  bordered
                  pagination={false}
                />
                <br />
                <h2>¿CON QUIÉN PODRÍAMOS COMPARTIR SUS DATOS?</h2>
                <br />
                <p>
                  Es posible que debamos compartir su información personal, con:
                  <br />
                  - Terceras empresas que subcontratamos o proveedores de
                  servicios. <br />- Terceros que necesitamos para gestionar
                  nuestro negocio. <br />
                  - Las entidades bancarias con las que trabajamos. <br />
                  Todos los proveedores o subencargados de tratamiento con los
                  que trabajamos están vinculados contractualmente con nosotros.
                  Podemos garantizar que cumplen con todas las medidas de
                  seguridad necesarias para salvaguardar tu información
                  personal, que utilizarán tus datos personales única y
                  exclusivamente para las finalidades especificadas, de acuerdo
                  con nuestras instrucciones. Si tiene alguna duda con los
                  subencargados con los que trabajamos puede enviar un correo a
                  privacy@ecoscooting.com y le facilitaremos un listado
                  actualizado de nuestros partners. También compartiremos
                  información personal con los cuerpos y fuerzas de seguridad
                  cuando la ley nos obligue a ello.
                </p>
                <br />
                <h2>¿DONDE ALOJAMOS TU INFORMACIÓN PERSONAL?</h2>
                <br />
                <p>
                  Toda la información que nos proporcione, tanto a través de
                  esta web como por otras vías o canales, será alojada en los
                  servidores CLOUD de AMAZON WEB SERVICE y en los servidores
                  CLOUD de ALIBABA. Dichos servidores están alojados dentro del
                  Espacio Económico Europeo.
                </p>{' '}
                <br />
                <h2>
                  TRANSFERENCIA INTERNACIONAL DE INFORMACIÓN PERSONAL
                </h2>{' '}
                <br />
                <p>
                  Con el fin de poder dar servicio, en ocasiones necesitaremos
                  transferir tus datos fuera del Espacio Económico Europeo
                  (EEE). Por ejemplo: * Para comunicarnos con Ud. o con nuestros
                  proveedores cuando se encuentren fuera de la UE; * Cuando
                  exista una dimensión internacional en los productos/servicios
                  que te proveemos.
                </p>{' '}
                <br />
                <p>
                  Las transferencias internacionales de datos están sujetas a
                  normas especiales regidas por los principios de las leyes de
                  protección de datos. Esto significa que únicamente podremos
                  transferir tus datos a países u organizaciones internacionales
                  exteriores a la UE, cuando: * El país receptor es considerado
                  como seguro por la autoridad competente en cuanto al nivel de
                  protección que aplica sobre los datos personales. * Se han
                  tomado todas las medidas pertinentes para garantizar la
                  salvaguarda de la seguridad y el correcto desarrollo de tus
                  derechos legales, así como la posibilidad de interponer
                  reclamaciones. * Exista una excepción aplicable según la ley
                  de protección de datos.
                </p>
                <br />
                <h2>
                  ¿DURANTE CUÁNTO TIEMPO CONSERVAREMOS SUS DATOS PERSONALES?
                </h2>{' '}
                <br />
                <p>
                  Sus datos se conservarán mientras dure la relación comercial
                  con nosotros o ejercite su derecho de cancelación u oposición,
                  o limitación al tratamiento. Sin embargo, conservaremos
                  determinados datos personales identificativos y del tráfico
                  durante el plazo máximo de 2 años para el caso de que fuera
                  requerido por los Jueces y Tribunales o para incoar acciones
                  internas derivadas del uso indebido de la página web.
                  Asimismo, le informamos que nuestras políticas de conservación
                  de la información se ajustan a los plazos que marcan las
                  distintas responsabilidades legales a efectos de prescripción:
                </p>{' '}
                <p>
                  {' '}
                  - Como norma general: En virtud de lo establecido en el
                  artículo 30 del Código de Comercio, y salvo otros criterios,
                  todos los documentos y/o información de la empresa se
                  conservará durante 6 años. Esto afecta a toda la documentación
                  contable, fiscal, laboral o mercantil, incluida la
                  correspondencia.
                </p>
                <p>
                  - Plazos específicos: Nuestra empresa también debe marcar unos
                  mínimos plazos en función de la tipología de datos que se
                  trate y atendiendo a los distintos plazos de prescripción, que
                  deberán conocer cada uno de los departamentos.
                </p>
                <p>
                  En este cuadro se relacionan los plazos de prescripciones que
                  afectan o pueden afectar a nuestra organización.
                </p>
                <br />
                <Table
                  columns={columns3}
                  dataSource={data3}
                  size="middle"
                  bordered
                  pagination={false}
                />
                <br />
                <p>
                  No será objeto de decisiones basadas en tratamientos
                  automatizados que produzcan efectos sobre sus datos.
                </p>
                <h2>NUESTRAS COMUNICACIONES</h2> <br />
                <p>
                  Toda la información personal que nos comunique se incorporará
                  a nuestros sistemas de información. Si acepta la presente
                  política de privacidad, significa que otorga a ECOSCOOTING el
                  consentimiento expreso a que realice las siguientes
                  actividades y/o acciones, mientras no nos indique lo
                  contrario: * A enviarle comunicaciones comerciales,
                  promocionales y de marketing directo por cualquier medio de
                  comunicación habilitado, para informarle de las actividades,
                  servicios, promociones, publicidad, noticias, ofertas y demás
                  información sobre los servicios y productos relacionados con
                  nosotros y nuestro grupo. * La conservación de los datos
                  durante los plazos previstos en las disposiciones aplicables.
                </p>
                <br />
                <h2>
                  ¿COMO DEJAR DE RECIBIR COMUNICACIONES DE MARKETING (OPT-OUT)?
                </h2>{' '}
                <br />
                <p>
                  En cualquier momento puede revocar cualquier consentimiento
                  expreso que nos haya otorgado para mandarle información
                  comercial. Para ello puede solicitar su baja mediante la
                  opción (opt-out) cuando esté habilitada en nuestra app/web, o
                  bien escribiéndonos un email con el asunto “baja” a
                  privacy@ecoscooting.com De acuerdo con la LSSICE, nunca
                  realizamos SPAM, por lo tanto, no le enviaremos correos
                  comerciales si no han sido solicitados o autorizados por Ud.
                  No obstante, en todas nuestras comunicaciones, tendrá la
                  posibilidad de revocar su consentimiento. No trataremos sus
                  datos personales para ninguna otra finalidad de las descritas
                  salvo por obligación legal o requerimiento judicial.
                </p>
                <br />
                <h2>RESPONSABILIDAD DEL USUARIO - DECLARACIÓN DE VERACIDAD</h2>
                <br />
                <p>
                  Al facilitarnos su información personal a través de canales
                  electrónicos, el usuario declara que es mayor de 14 años y que
                  todos los datos facilitados a ECOSCOOTING son verdaderos,
                  exactos, completos y actualizados. A estos efectos, el usuario
                  confirma que responde de la veracidad de los datos comunicados
                  y que mantendrá convenientemente actualizada dicha información
                  de modo que responda a su situación real, haciéndose
                  responsable de los datos falsos e inexactos que pudiera
                  proporcionar, así como de los daños y perjuicios, directos o
                  indirectos, que pudieran derivarse.
                </p>{' '}
                <br />
                <h2>SI NOS ENVÍA SU CURRICULUM</h2>
                <p>
                  En el supuesto de que nos quiera hacer llegar su CV a través
                  de nuestra web (apartado “trabaja con nosotros”) le informamos
                  que los datos aportados serán tratados para hacerle partícipe
                  de los procesos de selección que pueda haber, llevando a cabo
                  un análisis de su perfil profesional con el objetivo de
                  seleccionar la candidatura más adecuada para la eventual
                  vacante. No aceptamos currículums remitidos por otros canales
                  (por ejemplo entregados en mano en soporte papel). En caso de
                  producirse alguna modificación en los datos, le rogamos nos lo
                  comunique por escrito cuanto antes, con el fin de mantener sus
                  datos debidamente actualizados. Los currículums se conservarán
                  durante el plazo máximo de un año, transcurrido el cual se
                  procederá su destrucción segura y a la supresión de los todos
                  los datos incluidos. Le garantizamos un total respeto a la
                  confidencialidad. En este sentido, transcurrido el mencionado
                  plazo, si desea continuar participando en los posibles
                  procesos de selección, deberá remitir nuevamente su
                  currículum.
                </p>{' '}
                <br />
                <h2>¿COMO MANTENEMOS SU INFORMACIÓN SEGURA?</h2> <br />
                <p>
                  Nos tomamos muy en serio la protección de sus datos. Por este
                  motivo, garantizamos la implementación de las medidas de
                  seguridad, controles y procedimientos de carácter físico,
                  organizativo y tecnológico, apropiadas para evitar que su
                  información se pierda accidentalmente, se utilice o se acceda
                  a ella malintencionadamente. Limitamos el acceso a sus datos a
                  personas y entidades legitimadas para ello y nos aseguramos de
                  formar a todo nuestro personal debidamente. Todos los
                  intervinientes en el tratamiento de sus datos personales están
                  sujetos al deber de confidencialidad. Adicionalmente,
                  aplicamos procedimientos técnicos para reaccionar ante
                  cualquier sospecha que pudiera ser una brecha de seguridad de
                  los datos. En caso de ser necesario, le notificaremos de ello
                  así como a la autoridad de control (la AEPD en España), de
                  acuerdo con la normativa vigente.
                </p>{' '}
                <br />
                <h2>¿COMO EJERCER SU DERECHO ARCOLP?</h2> <br />
                <p>
                  Tanto el RGPD como la norma de transposición a la legalidad
                  española (la LOPDGDD), le garantiza el ejercicio de los
                  siguientes derechos. Los puede ejercer en cualquier momento y
                  siempre de forma gratuita:
                </p>{' '}
                <br />
                <Table
                  columns={columns4}
                  dataSource={data4}
                  size="middle"
                  bordered
                  showHeader={false}
                  pagination={false}
                />
                <p>
                  Para ejercer cualquiera de los derechos mencionados, por
                  favor, puede escribirnos con su solicitud al correo
                  electrónico específicamente habilitado al efecto:
                  privacy@ecoscooting.com. <br/> También puede contactar directamente
                  con nuestro Delegado de Protección de Datos:
                  xavi@aurisadvocats.com Deberá adjuntar a su solicitud
                  información sobre qué necesita con exactitud y, en todo caso,
                  acreditación de su identidad, válida en derecho.
                </p>{' '}
                <br />
                <h2>
                  La autoridad de control en materia de protección de datos{' '}
                </h2>
                <p>
                  Esperamos poder resolver cualquier cuestión o duda que pueda
                  preocuparle en relación a su información personal. Pero si
                  desea interponer una queja ante la autoridad competente, tiene
                  derecho a ello. En España la máxima autoridad en materia de
                  protección de datos es la Agencia Española de Protección de
                  Datos (AEPD). https://www.aepd.es/es - Tel: 91 266 35 17.
                </p>
                <br />
                <h2>Cambios en la presente política de privacidad</h2>
                <p>
                  ECOSCOOTING se reserva el derecho a modificar la presente
                  política para adaptarla a novedades legislativas o
                  jurisprudenciales.{' '}
                </p>
              </p>
            </div>
          </Col>
          <Col lg={3} sm={24} xs={24} span={8}></Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
}

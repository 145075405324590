import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Menu, Row, Col, Popover, Button } from "antd";
import Logo from "./images/Logo.svg";
import Tracking from "./Tracking";
import { Trans, withTranslation } from "react-i18next";
import Language from "./Language";
import Chatbot from "./Chatbot";
import { Alert } from "antd";
import i18next from "i18next";

const { SubMenu } = Menu;
class Header extends React.Component {
  static propTypes = {
    isFirstScreen: PropTypes.bool,
    isMoblie: PropTypes.bool,
  };
  state = {
    menuVisible: false,
  };
  onMenuVisibleChange = (visible) => {
    this.setState({
      menuVisible: visible,
    });
  };
  handleShowMenu = () => {
    this.setState({
      menuVisible: true,
    });
  };

  handleHideMenu = () => {
    this.setState({
      menuVisible: false,
    });
  };

  render() {
    const { t, i18n } = withTranslation("global");
    const { isFirstScreen, isMoblie } = this.props;
    const { menuVisible } = this.state;
    const menuMode = isMoblie ? "inline" : "horizontal";
    const headerClassName = classNames({
      clearfix: true,
      "home-nav-white": !isFirstScreen,
    });

    let language = "es";

    if (i18next?.languages?.length) {
      language = i18next.languages[0];
    }

    const menu = [
      <Menu mode={menuMode} id="nav" key="nav">
        <Menu.Item key="docs/services">
          <a href="#services">
            <Trans i18nKey="global:Header.services"></Trans>
          </a>
        </Menu.Item>
        <Menu.Item key="docs/hubs">
          <a href="#hubs">
            <Trans i18nKey="global:Header.hubs"></Trans>
          </a>
        </Menu.Item>
        {/*      <Menu.Item key="docs/fleet">
          <a href="#fleet">
            <Trans i18nKey="global:Header.fleet"></Trans>
          </a>
        </Menu.Item> */}
        {/* <Menu.Item key="docs/saas">
					<a href="#platform">
						<Trans i18nKey="global:Header.saas"></Trans>
					</a>
				</Menu.Item> */}
        <SubMenu
          key="Contact"
          title={<Trans i18nKey="global:Header.support"></Trans>}
        >
          <Menu.ItemGroup>
            <Menu.Item key="cont">
              {" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.t("ChatBot.urlChat")}
              >
                <Trans i18nKey="global:Header.contact"></Trans>
              </a>
            </Menu.Item>
            {/* <Menu.Item key="request">
              {' '}
              <a
                target="_blank"
                href={this.props.t('urlContact')}
              >
                <Trans i18nKey="global:Header.request"></Trans>
              </a>
            </Menu.Item> */}
            {/* <Menu.Item key="cont">
              {' '}
              <a
                target="_blank"
				rel="noopener noreferrer"
                href="https://ecoscooting.zendesk.com/hc/es/articles/14731291851665-Contacto"
              >
                <Trans i18nKey="global:Header.contact"></Trans>
              </a>
            </Menu.Item> */}
            <Menu.Item key="qa">
              {" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ecoscooting.zendesk.com/hc/es/articles/14730232529553-Preguntas-Frecuentes"
              >
                <Trans i18nKey="global:Header.questions"></Trans>
              </a>
            </Menu.Item>

            <Menu.Item key="ticket">
              {" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  language === "pt"
                    ? "https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=pt-BR&id=ecoscootingPt"
                    : "https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=es-ES&id=ecoscooting"
                }
              >
                <Trans i18nKey="global:Header.ticket"></Trans>
              </a>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <SubMenu
          key="lang"
          title={<Trans i18nKey="global:Header.languaje"></Trans>}
        >
          <Menu.ItemGroup>
            <Menu.Item key="lang">
              <Language />
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <Menu.Item key="docs/sigin">
          <Button type="primary" shape="round">
            <a
              style={{ color: "white" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.ecoscooting.com/login"
            >
              <Trans i18nKey="global:Header.login"></Trans>
            </a>
          </Button>
        </Menu.Item>
      </Menu>,
    ];

    return (
      <header id="header" className={headerClassName}>
        {menuMode === "inline" ? (
          <Popover
            overlayClassName="popover-menu"
            placement="bottomRight"
            content={menu}
            trigger="click"
            visible={menuVisible}
            arrowPointAtCenter
            onVisibleChange={this.onMenuVisibleChange}
          ></Popover>
        ) : null}
        <Row>
          <Col lg={1} md={2} sm={5} xs={5}>
            <a id="logo">
              <img alt="logo" src={Logo} />
            </a>
          </Col>
          <Col xl={5} lg={7} md={8} sm={15} xs={15}>
            <Tracking />
          </Col>
          <Col xl={15} lg={13} md={12} sm={4} xs={2}>
            {menuMode === "horizontal" ? menu : null}
          </Col>
          {/* <Col xl={3} lg={3} md={2}>
            <Language />
          </Col> */}
        </Row>
        <Chatbot />
        {/* <Alert
          message="MENSAJE DE ALERTA PARA UN FUTURO"
          banner
          closable
        /> */}
      </header>
    );
  }
}

export default withTranslation("global")(Header);
